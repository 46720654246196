@import 'styles/button';

:root {
  // Make sure these css variables match the ones declared in /consts/colors
  --MATTE_GRAY: #eeeeee;
  --PRIMARY: #1976d2;
  --PRIMARY_PALE: #e8f1fa;
  --SECONDARY_GRAY: #767676;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-vertical-10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.reorder-elem-dragged {
  opacity: 0.5;
}

.delete-icon-container {
  display: block;
  line-height: 0;
  position: absolute;
  top: 0;
  right: 0;
}

// Bulk upload view
.google-autocomplete-input {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

// Schedule Calendar
.schedule-react-calendar {
  width: 100% !important;
  border: 0px !important;

  .calendar-available {
    background: #9AE18F !important;
    // background: #589ee2 !important;
  }
  .calendar-focused-date {
    background: #52C041  !important;
    // background: #297ed2 !important;
  }
  .calendar-selected-date {
    background: #297ed2 !important;
  }
  abbr {
    text-decoration: none;
  }

  .react-calendar__tile:disabled {
    background-color: transparent;
    color: #838383
  }
  .react-calendar__navigation__label__labelText {
    font-weight: 600;
  }
  .react-calendar__month-view__days__day--weekend {
    color: black
  }

}
