.pressable {
  align-items: 'center';
  flex: 1;
  flex-direction: 'row';
  padding-left: 10;
  padding-top: 15;
  border-bottom-width: 2;
  border-bottom-color: var(--MATTE_GRAY);
}

.messages-view-container {
  display: flex;
  flex-direction: row;
  height: 600px;
}

.divider {
  background-color: var(--MATTE_GRAY);
  width: 2px;
  min-height: 100%;
  margin: 0 10px;
  display: block;
}

.chatrows-container {
  flex: 1;
  max-width: 400px;
  padding: 10px;
  overflow: auto;
  max-height: 600px;
}

.chatrow-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 10px 0;

  max-width: 400px;
}

.chatrow-container:hover {
  opacity: 0.75;
  cursor: pointer;
}

.selected {
  background-color: var(--PRIMARY_PALE) !important;
}

.profile-img {
  --IMG_SIZE: 64px;
  border-radius: var(--IMG_SIZE / 2);
  width: var(--IMG_SIZE);
  height: var(--IMG_SIZE);
  border-radius: 50%;
  margin-right: 15px;
  overflow: hidden;
  object-fit: cover;
}

.name {
  padding-bottom: 5;
  font-weight: bold !important;
}

.message {
  padding-top: 3.5;
  word-break: break-word;
}

.time {
  margin-right: 15;
  color: var(--SECONDARY_GRAY) !important;
}

.chat-info {
  flex: 1;
  flex-direction: 'column';
  justify-content: 'flex-start';
}

.name-line {
  flex-direction: 'row';
  justify-content: 'space-between';
}

.unread-icon {
  width: 8;
  height: 8;
  color: var(--PRIMARY);
  margin-right: 4px;
  margin-left: 4px;
}

.chatbox-container {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chatbox-header {
  font-weight: bold !important;
  /* position: absolute; */
  text-align: center;
  /* top: 0; */
  width: 100%;
}

.chatbox-content {
  width: 100%;
}

.chatbox-footer {
  /* position: absolute; */
  /* bottom: 0; */
  width: 100%;
}

.sendbird-channel-header__right-icon {
  /*
    The create new group channel button has to be hidden from view to
    to ensure users don't create new channels. This would cause errors
    in the mobile app.
  */
  display: none !important;
}

.sendbird-channel-preview__action {
  /*
    The channel preview action button has to be hidden from view to stop
    users from accidentally leaving channels.
  */
  display: none !important;
}

.sendbird-conversation__messages-padding {
  /*
    Get rid of bottom scrollbar in the channel component unless it's needed.
  */
  overflow: auto !important;
}

.sendbird-conversation__scroll-bottom-button {
  /*
    The scroll to bottom button causes unneccessary padding on the bottom
    of the chat screen, triggering another scrollbar to appear.
  */
  display: none !important
}

.sendbird-chat-header__right__info {
  /*
    Users are not supposed to be able to edit group channels
  */
  display: none !important
}

/*
  Disable active/hover/focus state styling since clicking the element doesn't do anything
*/
.sendbird-channel-header__title:active {
  border: initial !important;
}
.sendbird-channel-header__title:hover {
  background: initial !important;
}
.sendbird-channel-header__title:focus {
  background: initial !important;
}

.messages-search-field {
  margin-bottom: 10px;
  width: 320px;
  display: block;
}